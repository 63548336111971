<template>
  <component :is="componentInstance" v-if="componentId !== ''" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash-es/get'

export default {
  name: 'SaaSConnection',
  data() {
    return {
      componentId: '',
      componentInstance: '',
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps']),
  },
  async mounted() {
    this.disableLoading()
    this.componentId = this.$route.params.id
    const allowIds = this.apps.map((item) => item.id)
    if (!this.componentId || !allowIds.includes(Number(this.componentId))) {
      await this.$router.push('/404')
    }
    const selectedService = this.apps.filter((item) => item.id === Number(this.componentId))
    const componentName = this.getComponentNameByServiceName(get(selectedService[0], 'name', ''))
    if (componentName) {
      this.componentInstance = () =>
        import(`@/pages/saas-management/saas-connection/${componentName}.vue`)
    } else {
      await this.$router.push('/404')
    }
  },
  methods: {
    ...mapActions('loading', ['disableLoading', 'enableLoading']),
    getComponentNameByServiceName(name) {
      return name.replaceAll(' ', '-').toLowerCase()
    },
  },
}
</script>
